import React, { Component } from 'react';
import { BrowserRouter as Router, Route } from "react-router-dom";
import queryString from 'query-string'

import './App.css';
import axios from "axios";

import utf8 from "utf8";

class BlogPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            posts: [],
            on_detail_page: false
        };
    }

    componentDidMount() {
        axios.get(`https://xjf01d7r27.execute-api.us-east-1.amazonaws.com/Prod/posts`)
            .then(res => {
                const posts = res.data.posts;
                this.setState({ posts });
            });
    }

    render() {
        return (
            <div>
                {this.state.posts.map(item => (
                    <BlogList
                        key={item.post_id}
                        author={item.author}
                        date_published={item.date_published}
                        comment_count={item.comment_count}
                        title={item.title}
                        summary={item.summary}
                        post_url={item.post_id}
                        cover_img={item.cover_image}
                        tags={item.tags}
                    />))}

                <div className="row">
                    <div className="col-lg-12">
                        <nav className="blog-pagination justify-content-center d-flex">
                            <ul className="pagination">
                                <li className="page-item">
                                    <a href="#" className="page-link" aria-label="Previous">
                                  <span aria-hidden="true">
                                      <i className="ti-angle-left"></i>
                                  </span>
                                    </a>
                                </li>
                                <li className="page-item active"><a href="#" className="page-link">1</a></li>
                                <li className="page-item"><a href="#" className="page-link">2</a></li>
                                <li className="page-item">
                                    <a href="#" className="page-link" aria-label="Next">
                                  <span aria-hidden="true">
                                      <i className="ti-angle-right"></i>
                                  </span>
                                    </a>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>

        );
    }
}

class BlogList extends React.Component {
    render() {
        return (
            <div className="single-recent-blog-post">
                <div className="thumb">
                    <img className="img-fluid" src={this.props.cover_img} alt="" width="900"/>
                    <ul className="thumb-info">
                        <div>
                            <li key="1"><a href={this.props.post_url}><i className="ti-user"></i>{this.props.author}</a></li>
                            <li key="2"><i className="ti-notepad"></i>{this.props.date_published}</li>
                            <li key="3"><a href={this.props.post_url}><i className="ti-themify-favicon"></i>{this.props.comment_count}</a></li>
                        </div>
                    </ul>
                </div>
                <div className="details mt-20">
                    <a href={this.props.post_url}>
                        <h3>{this.props.title}</h3>
                    </a>
                    <p>{this.props.summary}</p>
                    <a className="button" href={this.props.post_url}>Read More <i className="ti-arrow-right"></i></a>
                </div>
            </div>
        );
    }
}

class Post extends React.Component {
    constructor(props){
        super(props);
        const values = queryString.parse(this.props.location.search)
        this.state = {
            cover_img: "",
            post_body: "",
            title: "",
            author: "",
            post_id: this.props.match.params.id

        }
        debugger;
    }

    componentDidMount() {
        let url = 'https://xjf01d7r27.execute-api.us-east-1.amazonaws.com/Prod/posts/' + this.state.post_id;
        console.log(url);
        axios.get(url)
            .then(res => {
                console.log(res.data)
                const posts = res.data;
                var bytes = utf8.decode(posts.post_body);
                this.setState({
                    cover_img: posts.cover_image,
                    post_body: b64DecodeUnicode(bytes),
                    title: posts.title,
                    author: posts.author
                });
            });
    }

    render() {
        return (
            <div className="main_blog_details">
                <img className="img-fluid" src={this.state.cover_img} alt=""/>
                    <div>
                        <h4>{this.state.title}</h4>
                    </div>
                    <div className="user_details">
                        <div className="float-right mt-sm-0 mt-3">
                            <div className="media">
                                <div className="media-body">
                                    <h5>{this.state.author}</h5>
                                    {/*<p>12 Dec, 2017 11:21 am</p>*/}
                                </div>
                                <div className="d-flex">
                                    <img width="42" height="42" src="img/blog/user-img.jpg" alt=""/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div dangerouslySetInnerHTML={{__html: this.state.post_body}} />

            </div>
        );
    }
}

export const App = () => {

  return (
          <Router>
              <div>
                  <Route path="/:id" component={Post} />
                  <Route exact path="/" component={BlogPage} />
              </div>
          </Router>
  );
};

function b64DecodeUnicode(str) {
    // Going backwards: from bytestream, to percent-encoding, to original string.
    return decodeURIComponent(atob(str).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
}

export default App;